import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Box } from '@rebass/grid';

import FauxLink from 'components/links/fauxLink';
import LinkColorChange from 'components/links/linkColorChange';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';

const Root = styled(FauxLink)`
  display: block;
  background-color: ${p => p.theme.white};
  border-radius: ${p => p.theme.borderRadius};
  border: 1px solid ${p => p.theme.greyLight};
  text-align: center;
  height: 100%;
  width: 100%;
`;

const ContactTeaser = ({ title, icon, children, theme, ...rest }) => {
  const Icon = icon;

  return (
    <Root {...rest}>
      <FauxLink as="div">
        <Box p={[3, 3, 5]}>
          <LinkColorChange>
            <Box mb={[1, 1, 2]}>
              <Icon size="l" />
            </Box>
            <Box mb={[1, 1, 2]}>
              <Heading rank="h3" margin="0">{title}</Heading>
            </Box>
          </LinkColorChange>
          <Text margin="0" color={theme.grey}>{children}</Text>
        </Box>
      </FauxLink>
    </Root>
  );
};

ContactTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ContactTeaser);
