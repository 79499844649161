import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import Container from "components/grid/container";
import BasicHero from "components/heros/basicHero";
import Heading from "components/typography/heading";
import Text from "components/typography/text";
import SignUpCta from "components/ctas/signUpCta";
import Ratio16by9 from "components/misc/ratio16by9";
import ContactTeaser from "components/pages/help-centre/contactTeaser";
import { EmailIcon, ChatIcon } from "components/icons/icons";
import Modal from "components/modals/modal";
import ModalHeader from "components/modals/modalHeader";
import ContactForm from "../components/forms/contactForm";
import { createHubspotContactUsTicket } from "../actions/formActions";
import Notification from "../components/notifications/notification";

const ContactContainer = styled(Container)`
  text-align: center;
`;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageModalOpen: false,
      loading: false,
      notificationResponse: ``,
    };
  }

  closeModal = (modal) => {
    this.setState({ [modal]: false });
  };

  handleContactUsFormSubmit = (values) => {
    values = {
      ...values,
      pageName: `Contact Us - The Coaching Manual`,
      pageUri: `https://www.thecoachingmanual.com/contact/`,
    };

    createHubspotContactUsTicket(values)
      .then(() => {
        this.setState({ messageModalOpen: false }, () => {
          this.stopFormSubmit();
        });

        this.setState({ notificationResponse: `success` });
        setTimeout(() => {
          this.setState({ notificationResponse: `` });
        }, 6000);
      })
      .catch(() => {
        this.setState({ messageModalOpen: false }, () => {
          this.stopFormSubmit();
        });

        this.setState({ notificationResponse: `error` });
        setTimeout(() => {
          this.setState({ notificationResponse: `` });
        }, 6000);
      });
  };

  stopFormSubmit = () => {
    this.setState({ loading: false });
  };

  render() {
    const { data } = this.props;
    const { messageModalOpen, loading, notificationResponse } = this.state;

    const {
      markdownRemark: {
        frontmatter: { hero },
      },
    } = data;

    return (
      <React.Fragment>
        {notificationResponse === `success` && (
          <Notification
            message="Your message has been successfully sent."
            type="success"
          />
        )}
        {notificationResponse === `error` && (
          <Notification
            message="There was an issue sending your message. You can always get in touch at help@thecoachingmanual.com."
            type="error"
          />
        )}

        <BasicHero title={hero.title} subtitle={hero.subtitle} />

        <ContactContainer my={[8, 8, `100px`]}>
          <Text style="h4">
            Our support team are available 9–5pm (UK time), Monday–Friday.
          </Text>

          <Flex flexWrap="wrap" mx={[-3, -3, -5]} justifyContent="center">
            <Box width={[1, 1 / 2]} px={[3, 3, 5]} mt={[3, 3, 5]}>
              <ContactTeaser
                as="button"
                onClick={() => this.setState({ messageModalOpen: true })}
                title="Message us"
                icon={ChatIcon}
              >
                (Opens a message form)
              </ContactTeaser>
            </Box>

            <Box width={[1, 1 / 2]} px={[3, 3, 5]} mt={[3, 3, 5]}>
              <ContactTeaser
                as="a"
                href="mailto:help@thecoachingmanual.com?subject=Enquiry from Contact page"
                title="Email us"
                icon={EmailIcon}
              >
                Email us at help@thecoachingmanual.com
              </ContactTeaser>
            </Box>
          </Flex>
        </ContactContainer>

        <Container my={[8, 8, `100px`]}>
          <Flex flexWrap="wrap" mx={[-3, -3, -5]}>
            <Box width={[1, 1, 1 / 2]} px={[3, 3, 5]} mt={[3, 3, 5]}>
              <Heading rank="h2">Manchester</Heading>
              <address>
                WeWork
                <br />
                29 John Dalton Street
                <br />
                Manchester
                <br />
                England
                <br />
                M2 6DS
                <br />
              </address>
              <Box mt={3}>
                <Ratio16by9
                  title="manchesterLocation"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9497.266982606005!2d-2.2462846!3d53.4806701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26ca0f685f42e44d!2sWeWork+Dalton+Place!5e0!3m2!1sen!2suk!4v1561992258088!5m2!1sen!2suk"
                  frameBorder="0"
                  allowFullScreen
                />
              </Box>
            </Box>

            <Box width={[1, 1, 1 / 2]} px={[3, 3, 5]} mt={[3, 3, 5]}>
              <Heading rank="h2">Los Angeles</Heading>
              <address>
                30211 Avenida De Las Banderas
                <br />
                Suite 200
                <br />
                Rancho Santa Margarita
                <br />
                CA 92688
                <br />
                <br />
              </address>
              <Box mt={3}>
                <Ratio16by9
                  title="laLocation"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.8402308226214!2d-117.60560628456415!3d33.63538438072138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dceba4309c49ab%3A0xf2dad9c5f8ac251b!2s30211+Avenida+De+Las+Banderas+%23200%2C+Rancho+Santa+Margarita%2C+CA+92688%2C+USA!5e0!3m2!1sen!2suk!4v1546876779796"
                  frameBorder="0"
                  allowFullScreen
                />
              </Box>
            </Box>
          </Flex>
        </Container>

        <SignUpCta />

        <Modal
          isOpen={messageModalOpen}
          closeModal={() => this.closeModal(`messageModalOpen`)}
          label="Contact form modal"
        >
          <ModalHeader
            title="Message us"
            onCloseClick={() => this.closeModal(`messageModalOpen`)}
          />

          <Box p={3}>
            <ContactForm
              loading={loading}
              onSubmit={(values) => {
                this.setState({ loading: true }, () => {
                  this.handleContactUsFormSubmit(values);
                });
              }}
            />
          </Box>
        </Modal>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...BasicHeroFragment
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;
